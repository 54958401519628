#Projhead{
    margin-top: 2.3rem;
    margin-bottom: 1.5rem;
    font-size: 3rem;
}
#breaky{
    margin-bottom: 1.5rem;
}
.link {
    color: rgb(255, 255, 255);
  }
.link:hover {
    color: #ffffff
}

a {
    color: rgb(255, 255, 255);
  }
a:hover {
    color: #ffffff
}