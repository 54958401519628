@media only screen and (max-width : 991px) {
    .navbar-collapse Button { 
        margin-bottom: 1.3rem;
    }
}
#seethrough{
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2) !important; 
    border-radius: 5px !important;
    background-color: rgba(255, 255, 255, .15) !important;
    backdrop-filter: blur(5px) !important;
    /* margin-bottom: 2.3rem; */
    /* max-height: 164px; */
}
.css-1wunjai-MuiAvatar-root{
    color: rgb(255, 255, 255);
}
.css-1wunjai-MuiAvatar-root:hover{
    color: rgb(255, 255, 255);
    background-color: #1565c0;
}
.IconButton {
    color: rgb(255, 255, 255);
  }
.IconButton:hover {
    color: #ffffff;
    background-color: #1565c0;
}